
import { Vue, Component, Prop, Emit } from 'vue-property-decorator';
import { NeSelect } from '@ne/ne-vue2-lib';
import { ClassList } from '@/store/layout-store/types';

@Component({
  name: 'ClassSelect',
  components: { NeSelect }
})
export default class ClassSelect extends Vue {
  @Prop() classes!: ClassList[];
  @Prop() value!: number | string;

  getLabel (metadata: { label: string }): string {
    if (metadata?.label) {
      return metadata?.label ?? '';
    }
    return '';
  }

  get items () {
    return this.classes.map(neClass => {
      return {
        id: neClass.id,
        text: neClass.name,
        metadata: {
          label: neClass.label
        }
      };
    });
  }

  @Emit()
  change (item: { id: number, text: string}) { return item.id; }
}
