

import { Component, Emit, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { Modal, ModalBody, ModalButton, ModalFooter, ModalHeader, RecursionTree, SearchBar } from '@/shared/components';
import { TreeNode } from '@/models/structure-mapping';
import { FacetsContainer, SelectedFacet } from '@/models/facets';
import { NeButton, NeIconButton } from '@ne/ne-vue2-lib';
import FiltersCopyrights from '@/shared/components/Filters/FiltersCopyrights/FiltersCopyrights.vue';
import { Copyright, StructureStatus, StructureStatusName, TestTypeIdEnum } from '@/models';
import { namespace } from 'vuex-class';
import { RouteName } from '@/router/models';
import { NameFiltr } from '@/config';
import { ClassList, LayoutModel, MenuModel } from '@/store/layout-store/types';
import { getFlattenTree } from '@/helpers/facets';
import ClassSelect from '@/shared/components/ClassSelect/ClassSelect.vue';
import { SET_FILTERS_ACTIVE } from '@/store/list';
import { debounce } from '@/helpers/debounce';

const AppStore = namespace('AppStore');
const LayoutStore = namespace('LayoutStore');
const ExerciseStore = namespace('ExerciseStore');

@Component({
  name: 'FiltersNew',
  components: { SearchBar, ClassSelect, NeButton, RecursionTree, FiltersCopyrights, NeIconButton, Modal, ModalHeader, ModalBody, ModalButton, ModalFooter }
})
export default class FiltersNew extends Vue {
  activeNodes: TreeNode[] = [];
  isCopyrightsOpen = false;
  filterKeyEnum = NameFiltr;
  isNewStructureModalShown = false;
  testTypeIdEnum = TestTypeIdEnum;
  isMobileMode = false;

  @Prop() facets!: FacetsContainer;
  @Prop() filtersKey!: number;
  @Prop() isExerciseDatabase: boolean;
  @Prop({ default: true }) isToggleButtonVisible: boolean;
  @Prop() isExerciseFilters: boolean;
  @Prop() menu: MenuModel;
  @PropSync('searchText') searchTextQuery: string;
  @PropSync('selectedItems') activeFacets!: SelectedFacet[];

  @ExerciseStore.State('earlySchoolEducation') isEarlyEducation: boolean;
  @AppStore.State('copyrights') copyrights: Copyright;
  @AppStore.State('structureStatus') structureStatus!: StructureStatusName;
  @LayoutStore.State('layout') layout: LayoutModel;
  @AppStore.State('isFiltersActive') isFiltersActive: boolean;
  @AppStore.Mutation(SET_FILTERS_ACTIVE) setFiltersActive: (isFiltersActive: boolean) => void;

  mounted () {
    this.handleWindowSizeChange();
  }

  handleWindowSizeChange () {
    const windowWidth = window.innerWidth;
    if (windowWidth < 768) {
      this.isMobileMode = true;
      if (this.isFiltersActive) this.setFiltersActive(false);
    } else {
      this.isMobileMode = false;
    }
  }

  showNewStructureInformation () {
    this.isNewStructureModalShown = true;
  }

  getPillRoute (categoryKey: string) {
    return {
      name: RouteName.TEST_DATABASE,
      params: {
        categoryKey: categoryKey.split('/').join(','),
        testTypeId: this.$route.params.testTypeId
      }
    };
  }

  get toggleStructureText (): string {
    const text =
      this.structureStatus === StructureStatus.NEW
        ? this.$tc('STRUCTURE_STATUS.go_to_old_structure')
        : this.$tc('STRUCTURE_STATUS.go_to_new_structure');
    return text.toString();
  }

  get showInNewStructure (): boolean {
    return this.structureStatus === StructureStatus.NEW;
  }

  isMenuLinkDisplayed (pill: ClassList): boolean {
    return this.structureStatus === StructureStatus.OLD
      ? !pill.newStructure && !!pill.mapCategoryId
      : pill.newStructure || !pill.mapCategoryId;
  }

  get classes (): ClassList[] {
    return this.menu.class.filter(item => {
      return this.isMenuLinkDisplayed(item);
    });
  }

  get classId (): number | string {
    const activeClass = this.menu.class.find(classItem => classItem.class === 'active');
    return activeClass ? activeClass.id : '';
  }

  isNumberofExercisesVisible (node: TreeNode): boolean {
    return this.isExerciseFilters && node.lvl! > 0 && node.key !== this.filterKeyEnum.IN_TEST;
  }

  showOndorio (node: TreeNode): boolean | null {
    const ondorioTypeMap = [
      'type-4',
      'type-5',
      'type-6',
      'type-7',
      'type-8',
      'type-9'
    ];
    return this.layout.user && this.layout.user.isOndorio && ondorioTypeMap.includes(`${node.key}-${node.id}`) && !this.isEarlyEducation && node.lvl! >= 2;
  }

  @Watch('facets', { immediate: true, deep: true })
  setActiveFacets () {
    const allFacets = getFlattenTree(this.facets.children);
    if (allFacets.length) {
      !this.activeFacets.length
        ? allFacets.forEach(item => {
          item!.active = false;
        })
        : this.activeFacets.forEach(activeFacet => {
          const facet = allFacets.find(item => `${item?.key}-${item?.id}` === `${activeFacet.nameFiltr}-${activeFacet.filterId}`) as TreeNode;
          facet.active = true;
          this.activeNodes.push(facet);
        });
    }
  }

  getLabelHtml (lvl: number, node: TreeNode): string {
    if (node.key === 'testType') {
      const label = node.name.charAt(0).toUpperCase() + node.name.slice(1);
      return lvl === 2 && node.id === 6 ? `<div><div class="test-type-blue"></div>${label}</div>` : `<div><div class="test-type-red"></div>${label}</div>`;
    }
    return lvl === 1 ? node.name.toUpperCase().replace('&NBSP;', '&nbsp;') : node.name;
  }

  isInputChecked (node: TreeNode): boolean | undefined {
    return !this.isExerciseFilters
      ? node.active || (node.children && this.getActiveChildrenLength(node) === node.children.length)
      : node.active || (node.children && this.handleExerciseFacetCheckbox(node));
  }

  handleExerciseFacetCheckbox (node: TreeNode): boolean {
    return this.isAllChildrenEmpty(node) ? false : this.getActiveChildrenLength(node) === this.getChildrenWithExercises(node)?.length;
  }

  isAllChildrenEmpty (node: TreeNode): boolean {
    if (node.children) {
      return node.children.map(item => item.numberOfExercises).reduce((a, b) => a! + b!, 0) === 0;
    }
    return false;
  }

  getChildrenWithExercises (node: TreeNode): TreeNode[] | null {
    if (node.children) {
      return node.children.filter(item => item.numberOfExercises);
    }
    return null;
  }

  getActiveChildrenLength (node: TreeNode): number | null {
    if (node.children) {
      return node.children.filter(item => item.active).length;
    }
    return null;
  }

  isIndeterminate (node: TreeNode | null): boolean {
    if (node?.children) {
      const children = this.isExerciseFilters ? this.getChildrenWithExercises(node) : node.children;
      const activeNodesLength = children?.filter(item => item.active).length;
      return activeNodesLength !== 0 && activeNodesLength !== children?.length;
    }
    return false;
  }

  isTreeVisible (node: TreeNode): boolean {
    if (this.$route.name === RouteName.TEST_COMPOSER && node?.children) return node.key !== NameFiltr.ACTIVE && node.children?.length > 0;
    if (node?.children) return node.children.length > 0;
    return false;
  }

  get icon () {
    return this.isFiltersActive ? 'arrow-left-4' : 'arrow-right-4';
  }

  get isAnyFilterActive (): boolean {
    return this.activeFacets.length > 0;
  }

  isMarkerVisible (node: TreeNode) {
    const activeKeys = this.activeNodes.map(node => node.parentId?.split('-')[0]);
    return activeKeys.includes(node.key);
  }

  changeFacetToggle () {
    this.setFiltersActive(!this.isFiltersActive);
    this.onToggleClick();
  }

  expandNode (node: TreeNode) {
    node.expanded = !node.expanded;
    node.children?.forEach((child: TreeNode) => {
      child.expanded = !child.expanded;
    });
    if (!node.children) this.changeCategory(node);
  }

  changeCategory (node: TreeNode) {
    this.handleActiveNodes(node);
    if (node.children) {
      const children = this.isExerciseFilters ? this.getChildrenWithExercises(node) : node.children;
      children?.forEach(child => {
        this.handleActiveNodes(child, node.active);
      });
    }
    this.activeNodes = [...new Set(this.activeNodes)];
    const mappedFacets = this.activeNodes.map(node => {
      return {
        nameFiltr: node.key,
        filterId: node.id
      } as SelectedFacet;
    });
    this.activeFacets = mappedFacets;
    if (this.isExerciseFilters) {
      localStorage.setItem('activeFacets', JSON.stringify(mappedFacets));
    }
    this.updateSelectedFilters();
  }

  handleActiveNodes (node: TreeNode, isParentActive?: boolean) {
    const parent = getFlattenTree(this.facets.children).find(facet => `${facet?.key}-${facet?.id}` === node.parentId);
    node.active = isParentActive ? true : !node.active;
    if (node.active) {
      this.activeNodes.push(node);

      const childrenLength = this.isExerciseFilters ? this.getChildrenWithExercises(parent!)?.length : parent!.children?.length;

      if (parent && this.getActiveChildrenLength(parent) === childrenLength) {
        parent.active = true;
        this.activeNodes.push(parent);
      }
    } else {
      this.activeNodes = this.activeNodes.filter(item => item.id !== node.id);

      if (parent) {
        parent.active = false;
        this.activeNodes = this.activeNodes.filter(item => {
          if (parent.key !== item.key) {
            return true;
          }
          return parent.id !== item.id;
        });
      }
    }
  }

  openCopyrightModal (): void {
    this.isCopyrightsOpen = true;
  }

  getTestTypeClass (testTypeId: TestTypeIdEnum): string {
    if (testTypeId === TestTypeIdEnum.TEST) return 'gt-filters-new__test-type--tests';
    if (testTypeId === TestTypeIdEnum.WORK_CARD) return 'gt-filters-new__test-type--work-card';
    return '';
  }

  @Emit()
  getFilteredTests (searchText: string) { return searchText; }

  @Emit()
  getFilteredExercises (searchText: string) { return searchText; }

  changeClass (id: string | number) {
    const chosenClass = this.menu.class.find(classItem => classItem.id === id);
    this.clearFilters();
    this.$router.push({ name: RouteName.TEST_DATABASE, params: { categoryKey: chosenClass!.categoryKey.split('/').join(',') } });
  }

  @Emit()
  updateSelectedFilters () {}

  @Emit('clear')
  clearFilters () {
    this.searchTextQuery = '';
    this.activeFacets = [];
    this.activeNodes.forEach(node => {
      node.active = false;
      if (node.children) {
        node.children.forEach(child => {
          child.active = false;
        });
      }
    });
    this.activeNodes = [];
    localStorage.removeItem('activeFacets');
  }

  @Emit()
  onToggleClick () {}
}
